.tableVacations {
  padding: 3% 10% 3% 10%;
}

.headerPlayer {
  margin: 2% 10% 0% 10%;
}

.informationMessage {
  float: left;
  margin-left: 1%;
  font-size: calc(7px + 0.4vw);
  color: black;
  font-style: normal;
  width: 50%;
  display: flex;
  padding-left: 10%;
}

.numberDaysOff {
  float: right;
  margin-left: -5%;
  width: 50%;
  text-align: right;
  padding-right: 10%;
}

p {
  margin-left: 1%;
}

#hr1 {
  width: 12%;
  height: 2px;
  margin: 0% 0% 0% 90%;
  color: rgb(0, 0, 0);
}

.botContainer {
  padding: 2% 10% 2% 10%;
}

.closeVacationsModal {
  background-color: blue;
  color: white;
  padding: 2% 3% 2% 3%;
  border-radius: 10%;
  float: right;
  width: 17%;
}

.playerContainer {
  margin: 12px 10% 0% 10%;
  display: flex;
}

.playerContainer h2 {
  font-size: 20px;
  padding-top: 17px;
}

.btn-container{
  display: flex;
  padding-left: 25px;
}

.btn-container .btn-add-days{
  height: 40px;
  border-radius: 10px;
  background-color: #3faa58 !important;
  padding: 2px 15px 2px 15px;
  color: white;
}

.btn-add-days:hover{
  background-color: #5ac775 !important;
}


