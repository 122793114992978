@import "src/assets/scss/core";

.container {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    margin-top: 36px;
    display: flex;
    justify-content: space-between;

    .breadCrumb {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-family: $roboto-bold;
      font-size: 26px;
      input {
        height: 100%;
        color: #6c757d;
        padding-left: 8px;
      }

      .global {
        display: flex;
        flex-direction: row;

        .icon {
          padding-right: 5px;
          color: $bts-logo-color-2;
        }
      }
      .newsLayout{
        color: $bts-logo-color-2;
      }

      .editTitle{
        float: right;
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }

  .bottom {
    flex: 9;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    button {
      height: 38px;
      width: 140px;
    }

    .section1 {
      display: flex;
      width: 120px;

      .articlePicture {
        padding-top: 2.5%;
        display: flex;
        width: 100%;
        .inputfile {
          width: 110px;
          height: 92px;
          border: 1px solid black;
          background: red;
          display: inline-block;
          outline: none;
          white-space: nowrap;
          opacity: 0;
          cursor: pointer;
          position: relative;
          z-index: 2;
          top: -92px;
          left: 0px;

        }
        .componentedit {
          display: flex;
          justify-content: flex-end;
        }
      }

      .articlePicture img {
        width: 110px;
        object-fit: cover;
        height: auto;
        border-radius: 2px;
        position: relative;
        padding-top: 20px;
      }
    }

    .section2 {
      width: 100%;
      flex-basis: 80%;
      display: flex;
      flex-direction: column;
      padding-bottom: 22px;
      padding-right: 22px;
      padding-left: 22px;

      button {
        width: 135px;
        height: 38px;
        padding: 11px 20px;
        gap: 10px;
        border: 1px solid #171F5E;
        border-radius: 5px;
        float: right;
        margin-top: 22px;
        margin-left: 11px;
      }

      .postedOn {
        display: flex;
        flex-direction: row;
        font-family: $roboto-regular;
        font-size: 16px;
        line-height: $line-height;
        letter-spacing: $letter-spacing;
        color: $wigo-posted;
      }

      .postedBy {
        font-family: $roboto-regular;
        font-size: 16px;
        line-height: $line-height;
        letter-spacing: $letter-spacing;
        color: #000000;
        font-weight: 300;
        padding-bottom: 30px;
        .author {
          font-family: $roboto-bold;
          font-weight: 400;
          font-style: normal;
        }

        .profilePicture {
          width: 39px;
          height: 39px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          margin: 10px;
        }
      }

      .articleText {
        font-family: $roboto-regular;
        font-size: 16px;
        letter-spacing: $letter-spacing;
        color: $black;
        width: 100%;
        display: flex;
        textarea {
          height: 300px;
          width: 100%;
          margin-bottom: 30px;
          resize: none;
          padding: 10px;
          border: 1px solid $dark-grey;
        }
      }

      .editContent{
        margin-top: -5px;
        margin-left: 6px;
      }
    }
  }

  .files{
    margin-top: 30px;
  }

  .dropzone {
    text-align: center;
    padding: 30px;
    border: 1px dashed #ABABAB;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
    width: 761px;
    height: 142px;
  }
  .selectedFile {
    color: #000;
    font-weight: bold;
  }
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #F8F7F7;
  width: 136px;
  height: 45px;
}
.versionText{
  margin-top: 15px;
  font-family: 'Inter';
  text-align: center;
  padding: 2px;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #CFCFCF;
}

@media only screen and (max-width: $maxMobile-width) {
  .top {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  .bottom {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
}
